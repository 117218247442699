import React from 'react';
import ReactDOM from 'react-dom';
import './globals.scss';
import Ruoter from './ruoter';
import "./lang/i18n.js";

/* const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Ruoter />
  </React.StrictMode>
); */

ReactDOM.render(
  <React.StrictMode>
    <Ruoter />
  </React.StrictMode>,
  document.getElementById("root")
);

